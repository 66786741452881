import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../../util/theme";
import { Carousel } from "react-responsive-carousel";
import { laptopSize, mobileSize } from "../../../../util/variables";
import LeftQuoteSrc from "../../../../content/img/homepage/left-quote.svg";
import RightQuoteSrc from "../../../../content/img/homepage/right-quote.svg";
import * as sanitizeHtml from "sanitize-html";
import { AppText, FlexDiv } from "../../../ui";
import StarsSrc from "../../../../content/assets/Stars.svg";

const TESTIMONY_SLIDES = [
  {
    circleColor: "rgba(197, 200, 0, 0.75)",
    text:
      "Imprint is <b>completely life changing.</b> As a person that always loves reading but doesn’t have much time, this app is <b>perfect for me.</b>",
  },
  {
    circleColor: "rgba(255, 240, 63, 0.75)",
    text:
      "This app is a <b>must-have</b> for the avid reader and learner… The visuals really do wonders!",
  },
  {
    circleColor: "rgba(255, 175, 204, 0.75)",
    text:
      "Imprint helps me <b>stay engaged.</b> I retain more information than I would if I read on my own. I would recommend it to anyone trying to <b>pick up better habits and learn more.</b>",
  },
  {
    circleColor: "rgba(255, 48, 0, 0.75)",
    text:
      "The time I spent doom scrolling has been replaced with <b>absorbing information</b> that’s <b>actually useful</b> to me. I always feel better leaving the app than when I went in.",
  },
];

export const TestimonyCarousel = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const onChange = (slideNumber: number) => setSlideNumber(slideNumber);

  return (
    <CarouselWrapper>
      <StyledCarousel
        infiniteLoop
        showArrows
        centerMode
        width="95%"
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        onChange={onChange}
      >
        {TESTIMONY_SLIDES.map(testimony => (
          <CarouselSlide key={testimony.text} text={testimony.text} />
        ))}
      </StyledCarousel>
      <LeftQuote>
        <img src={LeftQuoteSrc} alt='"' width={60} height={64} />
        <Circle color={TESTIMONY_SLIDES[slideNumber].circleColor} />
      </LeftQuote>
      <RightQuote>
        <img src={RightQuoteSrc} alt='"' width={60} height={64} />
        <Circle color={TESTIMONY_SLIDES[slideNumber].circleColor} />
      </RightQuote>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1012px;
  width: 100%;
  .carousel-root {
    width: 100%;
  }
`;

const StyledCarousel = styled(Carousel)`
  text-align: center;
  .carousel {
    background-color: ${theme.WHITE_COLOR};
    margin: 0 auto;
    border-radius: 16px;
    padding: 64px 0;
    @media ${mobileSize} {
      padding: 32px 0;
    }
  }
  .carousel.carousel-slider .control-arrow,
  .carousel .control-arrow {
    max-height: 90%;
    background-color: rgba(0, 0, 0, 0);
    /* opacity: 0.; */
  }

  .carousel .control-next.control-arrow::before,
  .carousel .control-prev.control-arrow::before {
    content: "";
    position: absolute;
    border: solid #fff;
    border-width: 0 6px 6px 0;
    display: inline-block;

    padding: 12px;
    box-shadow: none;
    background: none;
  }

  .carousel .control-next.control-arrow::before {
    right: -60px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    @media ${mobileSize} {
      right: -40px;
    }
  }

  .carousel .control-prev.control-arrow::before {
    left: -60px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);

    @media ${mobileSize} {
      left: -40px;
    }
  }

  .carousel.carousel-slider {
    overflow: inherit;
  }
  /* .carousel .slider-wrapper.axis-horizontal .slider .slide {
    min-width: 100%;
  } */
  li {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  ul .selected {
    opacity: 1;
  }
  div {
    height: 100%;
  }
`;

interface CarouselSlideProps {
  text: string;
}

export const CarouselSlide: React.FC<CarouselSlideProps> = ({ text }) => {
  return (
    <FlexDiv direction="column" justify="center" align="center">
      <AppText
        noMargin
        fontSize={32}
        mobileFontSize={18}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}
        style={{ width: 642, maxWidth: "86%" }}
      />
      <Stars
        src={StarsSrc}
        alt="5 stars"
        width={144}
        height={27}
        style={{ filter: "brightness(0)", marginTop: 24 }}
      />
    </FlexDiv>
  );
};

const LeftQuote = styled.div`
  position: absolute;
  left: 64px;
  top: 48px;

  img {
    position: absolute;
    display: block;
    z-index: 1;
  }

  div {
    position: relative;
    left: 20px;
    bottom: -24px;
    /* opacity: 0.5; */
    z-index: 0;
  }

  @media ${mobileSize} {
    left: 24px;
    top: 0px;
    div {
      left: 12px;
    }
  }
`;

const RightQuote = styled.div`
  position: absolute;
  right: 64px;
  bottom: 48px;

  img {
    position: absolute;
    display: block;
    z-index: 1;
  }

  div {
    position: relative;
    right: 20px;
    top: 24px;
    /* opacity: 0.5; */
    z-index: 0;
  }
  @media ${mobileSize} {
    right: 24px;
    bottom: 40px;
    div {
      right: 12px;
    }
  }
`;

const Circle = styled.div<{ color: string }>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: ${props => props.color};
  transition: background-color 0.5s ease-in-out;
  z-index: 0;

  @media ${laptopSize} {
    width: 48px;
    height: 48px;
  }

  @media ${mobileSize} {
    width: 28px;
    height: 28px;
  }
`;

const Stars = styled.img`
  @media ${mobileSize} {
    width: 90px;
    height: 17px;
  }
`;
