import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { mobileSize } from "../../util/variables";
import { Wrapper, AppText, AppLogo } from "../ui";
import theme from "./../../util/theme";
import instagramLogo from "../../content/img/icon/instagram.png";
import facebookLogo from "../../content/img/icon/facebook.png";
import twitterLogo from "../../content/img/icon/twitter.png";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const BottomNavSegment: React.FC = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <AppLogo white size="large" />
        <ContentDiv>
          <FooterAppText>The world’s most important knowledge, visualized.</FooterAppText>
          <LinksDiv>
            <LinkText to="/about/">About</LinkText>
            <AnchorLinkText href="https://imprint.applytojob.com/">Careers</AnchorLinkText>
            <AnchorLinkText href="mailto:info@imprintapp.com">Contact Us</AnchorLinkText>
            <AnchorLinkText
              href="https://docs.google.com/document/d/1CF6JLDUCvQpdKegxKuM4Sm96QFlfwhuORv1sEGMfHPw/edit"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </AnchorLinkText>
            <AnchorLinkText
              href="https://docs.google.com/document/d/1XwD2NWvdf4zZhRKYjBvPD4Q7S0f4-OhnaK8orj0GpJM/edit"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </AnchorLinkText>
          </LinksDiv>
        </ContentDiv>
        <ContentSocialDiv>
          <FooterAppText>© {new Date().getFullYear()} Imprint</FooterAppText>
          <LinksSocialDiv>
            <SocialLink
              href="https://www.instagram.com/get_imprint/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialImg src={instagramLogo} width="20px" alt="Instagram" />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/imprintapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialImg src={facebookLogo} alt="Facebook" width="11px" />
            </SocialLink>
            <SocialLink
              href="https://twitter.com/imprint"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialImg src={twitterLogo} alt="Twitter" width="23px" />
            </SocialLink>
          </LinksSocialDiv>
        </ContentSocialDiv>
      </Wrapper>
    </FooterContainer>
  );
};

const SocialLink = styled(OutboundLink)`
  margin-left: 25px;
`;

const SocialImg = styled.img`
  height: 20px;
`;

const LinkText = styled(Link)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${theme.WHITE_COLOR};
  margin-left: 40px;
  @media ${mobileSize} {
    margin-left: 0px;
    margin-bottom: 20px;
  }
`;

const AnchorLinkText = styled(OutboundLink)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${theme.WHITE_COLOR};
  margin-left: 40px;
  @media ${mobileSize} {
    margin-left: 0px;
    margin-bottom: 20px;
  }
`;

const LinksDiv = styled.div`
  display: flex;
  @media ${mobileSize} {
    margin-top: 20px;
    flex-direction: column;
  }
`;

const LinksSocialDiv = styled.div`
  display: flex;
`;

const FooterAppText = styled(AppText as any)`
  font-size: 15px;
  line-height: 20px;
  color: ${theme.WHITE_COLOR};
  max-width: 220px;
`;

const ContentSocialDiv = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media ${mobileSize} {
    margin-top: 22px;
  }
`;

const ContentDiv = styled(ContentSocialDiv as any)`
  margin-top: 20px;
  @media ${mobileSize} {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const FooterContainer = styled.footer`
  padding-top: 54px;
  padding-bottom: 54px;
  width: 100%;
  display: block;
  text-align: left;
  background-color: ${theme.BLACK_COLOR};
  height: 335px;
  @media ${mobileSize} {
    height: auto;
  }
`;

export { BottomNavSegment };
